
import { XIcon } from 'vue-feather-icons'
export default {
  name: 'FCAlert',
  components: { XIcon },
  data: () => ({ show: false, type: '', title: '', text: '' }),
  computed: {
    alert () { return this.$store.state.alertObj }
  },
  watch: {
    alert (val) {
      if (val) { this.open({ type: val.type, title: '', text: val.msg }) } else { this.close() }
    }
  },
  mounted () {
    if (this.alert) { this.open({ type: this.alert.type, title: '', text: this.alert.msg, sticky: this.alert.sticky }) }
  },
  methods: {
    open ({ type, title, text }) {
      this.type = type
      this.title = title
      this.text = text
      this.show = true

      if (!this.alert.sticky) { setTimeout(() => { this.close() }, 3000) }
    },
    close () {
      this.show = false
    }
  }
}
